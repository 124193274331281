import React from "react"
import Seo from "../app/services/seo"
import Layout from "../app/layouts/default"
import EditorialIntro from '../app/components/editorial';
import Parser from "html-react-parser";
import Button from "../app/atoms/button";
import LanguagesList from "../app/components/languages-list";

const breadcrumbs = [
    {
        link: '/',
        label: 'Home'
    },
    {
        label: 'Cambia Lingua'
    }

];

const languages = [
    {link: "#", label: "België - Nederlands"},
    {link: "#", label: "Belgique - Français"},
    {link: "#", label: "Česká Republika - Čeština"},
    {link: "#", label: "Danmark - Dansk"},
    {link: "#", label: "Deutschland - Deutsch"},
    {link: "#", label: "Eesti - Eesti"},
    {link: "#", label: "Ελλάδα - Ελληνικά"},
    {link: "#", label: "España - Español"},
    {link: "#", label: "Finland - English"},
    {link: "#", label: "France - Français"},
    {link: "#", label: "Hrvatska - Hrvatski"},
    {link: "#", label: "Italia - Italiano", selected: true},
    {link: "#", label: "Latvija - Latviešu"},
    {link: "#", label: "Lietuva - Lietuvių"},
    {link: "#", label: "Magyarország - Magyar"},
    {link: "#", label: "Maroc - Français"},
    {link: "#", label: "Nederland - Nederlands"},
    {link: "#", label: "Österreich - Deutsch"},
    {link: "#", label: "Polska - Polski"},
    {link: "#", label: "Portugal - Português"},
    {link: "#", label: "România - Română"},
    {link: "#", label: "Россия - Русский"},
    {link: "#", label: "Schweiz - Deutsch"},
    {link: "#", label: "Slovenija - Slovenščina"},
    {link: "#", label: "Slovensko - Slovenčina"},
    {link: "#", label: "Srbija - Srpski"},
    {link: "#", label: "Suisse - Français"},
    {link: "#", label: "Suomi - Suomi"},
    {link: "#", label: "Sverige - Svenska"},
    {link: "#", label: "Sweden - English"},
    {link: "#", label: "Svizzera - Italiano"},
    {link: "#", label: "United Kingdom - English"},
    {link: "#", label: "Україна - Українська"},
    {link: "#", label: "България - Български"},
    {link: "#", label: "Россия - Pусский"}
];

const ChangeLanguagePage = () => {
    return (
        <Layout noMarginFooter={true} breadcrumbs={breadcrumbs}>
            <Seo title="About Us page" keywords={[`gatsby`, `application`, `react`]}/>
            <div className="container">
                <EditorialIntro>
                    <div className="editorial-intro">
                        <h2>Select Your Country</h2>
                        <div className="p">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorem eos esse excepturi fugit ipsum iure libero nulla numquam odit pariatur placeat quis, quod tempora vel vero vitae voluptatem voluptates?</div>
                    </div>
                </EditorialIntro>
                <LanguagesList languages={languages} />
            </div>
        </Layout>
    )
};

export default ChangeLanguagePage;
