/**
 * LanguagesList
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class LanguagesList
 * @param {object} props - Props
 */

import React from "react";
import {Link} from '../../atoms/link';

const LanguagesList = (
	{
		languages = []
	}
) => {

	return (
		<div className="language-list">
			{languages.map( (language, idx) => (
				<Link to={language.link} className={`language-list__item ${language?.selected ? 'language-list__item--selected' : ''}`}>{language.label}</Link>
			))}
		</div>
	);
}

export default LanguagesList;
