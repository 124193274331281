/**
 * Editorial
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Editorial
 * @param {object} props - Props
 */

import React from "react";
// import Parser from 'html-react-parser';

const Editorial = ({ children, textWall }) => {

	return (
		<section className={`editorial ${textWall === true ? "editorial--text-wall" : ""}`}>
			{children}
		</section>
	);
}

export default Editorial;